import {castArray} from 'lodash'
import {For} from 'babel-plugin-jsx-control-statements'
import allEnums from '../../../../constants/enums'
import {Chip} from '../../../visual'


const enumCell = (enumName, fallback = '–') => {
  const renderEnumCell = ({value}) => {
    if (!value) return <Chip label={fallback} />
    return (
      <For each="item" of={castArray(value)} index="idx">
        <Chip key={idx} label={allEnums[enumName][item]} />
      </For>
    )
  }
  return renderEnumCell
}

export default enumCell
